import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Svjetovi = () => (
  <Layout>
    <SEO title="Svjetovi u meni" />
    <div class="styledtext layout__container--med">
      <h3>Svjetovi u meni</h3>
      <p>
        Svijet u kojem bivstvujemo šaren je, raznolik, prostran. Na nekim mjestima pevladava buka, drugima opet vlada tišina. Unutar velikoga Svijeta postoje mali svjetovi, naši svjetovi, koji se dodiruju, isprepliću, nadopunjuju, zaobilaze, traže i u interakciji transformiraju stvarajući iskustva unutar igre zvane Život.
      </p>

      <p>
        Iskustva susreta svjetova snažno su obojana emocijama  pohranjenim u naša srca koja bilježe sve proživljeno posebnim sustavom znakova, značajno različitim od sustava znakovakojima se služi mozak.
      </p>

      <p>
        Srce traži ponavljanje onih iskusava koja su donijela ekspanziju dušeotkrivanje vlastitoga mjesta u svijetui svjetova u sebi. Među emocijama rođenim u iskustvima želimo ponoviti one koje nam donose osjećaj vrijednosti, pripadnosti, sigurnosti, mira, opuštenosti i slobode.
      </p>

      <p>
        Srce žudi za osjećajem slatkoće postojanjaujedinjenom u osjećaju Ljubavikoja uživa u našem postojanju bez zahtjeva da budemo ovo ili onoda bismo tu Ljubav zaslužili.
      </p>

      <p>
        Iskustva obojena istinskom slatkoćom postojanja mogu nam donijeti isključivo ljudi s kojima osjetimo dubinsku povezanost na molekularno-staničnoj, atomsko-fotonskoj vibracijskoj razini na kojoj smo istinski svoji - bez filtera, bez okova društva, bez ega i uma, bez imperativa postojanja na zadani način i bez potrebe uklapanja u zadanu sliku svijeta.
      </p>

      <p>
        Takvi su ljudi privilegij koji uživamo i s njima postižemo svoju istinsku svrhu - bivamo svojima i uživamo u tome bivanju. Takvi su ljudi privilegij jer u njihovom je društvu tišina katkada rječitija od riječi. Takvi su ljudi privilegij jer s njima u bujici riječi pronalazimo jasnoću i osjetimo smisao jednostavnog postojanja u sadašnjem trenutku. 
      </p>
      
      <p>
        S njima susrete ne moramo zamišljati da bi oni bili kao izmišljeni, da bi bili bajkoviti u svojoj jednostavnosti, spontanosti i čudesnosti. S njima su i nesporazumi nevidljivi sporazumi duša da će, učeći na iskustvima, u metamorfozi razvijati međusobni odnos kroz cjelovitu ljestvicu emocija, bez bijega od onih zahtjevnijih i izazovnijih. S njima su razgovori tečni i kada zapinju jer se na razini duše razumijemo prije nego na razini riječi; jer na razini duše komuniciramo i kada nismo u istome prostoru; jer postoje mikrosvjetovi u kojima se naši susreti događaju kad god to poželimo i mislima odletimo jedni drugima. 
      </p>
      
      <p>
        Takvi su ljudi privilegij jer s njima preskačemo razine, prelazimo mostove, pomičemo granice…; jer i sebe više volimo zbog osjećaja kojeg oni u nama bude; jer smo i sami sebi ljepši kada se gledamo njihovim očima.
      </p>
    </div>
  </Layout>
)

export default Svjetovi
